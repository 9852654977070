import React from 'react';

export default function Popup(props: any) {
  const [popUp, setPopUp] = React.useState(false);

  function toggle() {
    setPopUp((prevPopUp) => !prevPopUp);
  }

  return (
    <section>
      <div
        className="cursor-pointer border border-gray-300 rounded-lg p-6 text-center hover:shadow-md transition-shadow duration-300"
        onClick={toggle}
      >
        <h3 className="text-gray-800 font-light text-xl">{props.title}</h3>
        <img
          className="w-full h-auto object-cover rounded mt-4"
          src={props.image}
          alt={props.title || 'Image'}
        />
      </div>

      {popUp && (
        <div className="fixed inset-0 bg-black bg-opacity-60 flex items-center justify-center z-50">
          <div className="relative bg-white border border-gray-200 rounded-lg p-8 md:w-auto md:max-w-lg w-full max-h-[80%] overflow-auto shadow-lg">
            <button
              className="absolute top-4 right-4 text-gray-800 text-2xl font-light cursor-pointer"
              onClick={toggle}
              aria-label="Close popup"
            >
              &times;
            </button>
            <h3 className="text-2xl font-light mb-4 text-gray-800">{props.title}</h3>
            <p className="text-gray-600 font-light">{props.description}</p>
          </div>
        </div>
      )}
    </section>
  );
}
