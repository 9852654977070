import React, { useEffect, useState } from 'react';
import KitchenBathroomHeader from '../CustomComponents/KitchenBathroomHeader';
import Head from '../PageComponents/KitchenBathroomComponent/Head';
import PricingTable from '../Data/pricingTable'; // Import the new PricingTable component
import { fetchKitchenPage, RoomData } from '../Data/kitchenBathroomData';
import ImageGallery from '../PageComponents/GalleryComponent/imagegallery';
import KitchenMade from '../PageComponents/KitchenComponents/KitchenMade';

export default function Kitchen() {
  const [kitchenData, setKitchenData] = useState<RoomData | null>(null);

  useEffect(() => {
    const loadKitchenData = async () => {
      try {
        const data = await fetchKitchenPage();
        setKitchenData(data);
      } catch (error) {
        console.error('Error loading kitchen data:', error);
      }
    };

    loadKitchenData();
  }, []);

  if (!kitchenData) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <KitchenBathroomHeader
        images={[kitchenData.headlineImage]}
        title={kitchenData.headline}
        subtitle="Luxurious Kitchens"
        height="60vh"
      />
      <Head
        heading={kitchenData.headline}
        images={[kitchenData.mainImage]}
        paragraphs={[kitchenData.description]}
      />
      <KitchenMade />
      <PricingTable
        roomType="Kitchen" // or "Kitchen"
        title="Kitchen Pricing"
        subtitle="Choose the best plan for your kitchen"
        extraInfo="All prices include VAT and installation."
      />
      <ImageGallery category="kitchen"  />
    </div>
  );
}
