import React, { useState, useEffect, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useProjects } from '../../CustomComponents/projectsContext';
import FullscreenImage from '../../CustomComponents/FullScreenImage';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const ProjectsDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { projects } = useProjects();

  // Find the current project
  const project = projects.find((project) => project.id === id);

  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef<HTMLDivElement>(null);

  // State for fullscreen mode
  const [currentImageIndex, setCurrentImageIndex] = useState<number | null>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (sectionRef.current) {
        const { top } = sectionRef.current.getBoundingClientRect();
        const windowHeight = window.innerHeight;
        if (top < windowHeight * 0.75) {
          setIsVisible(true);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Check visibility on initial render

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Handle opening the fullscreen
  const openFullscreen = (index: number) => {
    setCurrentImageIndex(index);
    // Add fullscreen class to body
    document.body.classList.add('fullscreen');
  };

  // Handle closing the fullscreen
  const closeFullscreen = () => {
    setCurrentImageIndex(null);
    // Remove fullscreen class from body
    document.body.classList.remove('fullscreen');
  };

  // Go to the next image in fullscreen mode
  const nextImage = () => {
    if (currentImageIndex !== null && project?.images) {
      setCurrentImageIndex((currentImageIndex + 1) % project.images.length);
    }
  };

  // Go to the previous image in fullscreen mode
  const prevImage = () => {
    if (currentImageIndex !== null && project?.images) {
      setCurrentImageIndex((currentImageIndex - 1 + project.images.length) % project.images.length);
    }
  };

  if (!project) {
    return <div>Project not found</div>;
  }

  return (
    <div
      ref={sectionRef}
      className={`bg-gray-100 pb-16 transition-all duration-1000 transform ${
        isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'
      }`}
    >
      <div className="container mx-auto px-6 lg:px-8 max-w-screen-xl">
        {/* Back Link */}
        <Link to="/projects" className="text-gray-800 hover:text-gray-600 mt-4 mb-4 inline-block">
          &larr; Back to All Projects
        </Link>

        {/* Project Title */}
        <h1 className="text-4xl font-light  mb-12 text-gray-900">
          {project.title}
        </h1>

        {/* Image Gallery */}
        {project.images && project.images.length > 0 ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mb-8">
            {project.images.map((image, index) => (
              <div
                key={index}
                className="relative cursor-pointer group" // Added group for hover
                onClick={() => openFullscreen(index)}
              >
                <img
                  src={image}
                  alt={`Gallery  ${index + 1}`}
                  className="w-full h-full object-cover"
                />
                {/* Darkening effect on hover */}
                <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-50 transition-opacity duration-300" />
              </div>
            ))}
          </div>
        ) : (
          <p>No images available for this project.</p>
        )}

        {/* Fullscreen Mode */}
        {currentImageIndex !== null && project.images && (
          <FullscreenImage
            imageUrl={project.images[currentImageIndex]}
            onPrevious={prevImage}
            onNext={nextImage}
            onClose={closeFullscreen}
          />
        )}

        {/* Project Description */}
        <div className="lg:flex lg:space-x-4">
          <div className="lg:w-full">
            <hr className="border-gray-300 my-8" />
            <div className="text-gray-700 font-light mb-8 whitespace-pre-line">
              {project.description
                ? documentToReactComponents(project.description)
                : 'No description available.'}
            </div>
            <hr className="border-gray-300 my-8" />
            <ul className="list-none flex flex-wrap text-gray-700 mb-8">
              {project.keyFeatures.map((feature, index) => (
                <li key={index} className="mr-4 mb-2 bg-gray-200 px-3 py-1">
                  {feature}
                </li>
              ))}
            </ul>
            <hr className="border-gray-300 my-8" />
          </div>
        </div>

        {/* Similar Projects */}
        <div className="w-full mt-16">
          <h2 className="text-3xl font-light mb-8">Similar Projects</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            {projects
              .filter((proj) => proj.id !== id)
              .slice(0, 3)
              .map((proj) => (
                <article
                  key={proj.id}
                  className="relative isolate flex flex-col justify-end overflow-hidden bg-gray-900 px-8 pb-8 pt-80 sm:pt-48 lg:pt-80 transition-transform duration-300 transform hover:scale-105"
                >
                  <img
                    src={proj.images[0]}
                    alt={proj.title}
                    className="absolute inset-0 -z-10 h-full w-full object-cover"
                  />
                  <div className="absolute inset-0 -z-10 bg-gradient-to-t from-black via-black/40" />
                  {/* Darkening effect on hover */}
                  <div className="absolute inset-0 bg-black opacity-0 hover:opacity-50 transition-opacity duration-300" />
                  <h3 className="mt-3 text-lg font-light text-white">
                    <Link to={`/projects/${proj.id}`}>
                      <span className="absolute inset-0" />
                      {proj.title}
                    </Link>
                  </h3>
                  <p className="mt-2 text-sm text-gray-300">
                    {typeof proj.description === 'string'
                      ? proj.description.substring(0, 100)
                      : 'No description available'}...
                  </p>
                </article>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectsDetails;
