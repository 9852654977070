import client from '../CustomComponents/Contentful/contentfulClient';

export interface PricingTier {
  name: string;
  id: string;
  priceTotal: string;
  description: string;
  features: string[];
  mostPopular: boolean;
}

export interface RoomData {
  headline: string;
  headlineImage: string;
  mainImage: string;
  description: string;
  pricingTiers: PricingTier[];
  images: string[];
  introForHomepage: string;
}

// Function to fetch pricing tiers based on their IDs
const fetchPricingTiers = async (tierIds: string[]): Promise<PricingTier[]> => {
  if (!tierIds || !Array.isArray(tierIds)) {
    return []; // Return an empty array if tierIds is not valid
  }

  const tiers = await Promise.all(
    tierIds.map(async (tierId) => {
      const tierResponse = await client.getEntry(tierId);
      const tierFields = tierResponse.fields as any;

      return {
        name: tierFields.name || "Unknown Name",
        id: tierId,
        priceTotal: tierFields.priceTotal || "N/A",
        description: tierFields.description || "No description provided.",
        features: tierFields.features || [],
        mostPopular: tierFields.mostPopular || false,
      };
    })
  );

  return tiers;
};

// Function to fetch data for the Kitchen page
export const fetchKitchenPage = async (): Promise<RoomData> => {
  try {
    const response = await client.getEntries({
      content_type: 'kitchenPage',
      limit: 1,
    });

    console.log('Kitchen Page Response:', response);

    if (response.items.length > 0) {
      const content = response.items[0].fields as any;

      console.log('Kitchen Images:', content.kitchenImages);

      const pricingTiers = await fetchPricingTiers(
        content.pricingTiers ? content.pricingTiers.map((tier: any) => tier.sys.id) : []
      );

      return {
        headline: content.kitchenHeadline || "Kitchen Headline",
        headlineImage: content.headlineKitchenImage?.fields?.file?.url || `/images/placeholder-kitchen.jpg`,
        mainImage: content.mainKitchenImage?.fields?.file?.url || `/images/placeholder-kitchen.jpg`,
        description: content.kitchenDescription || "Default kitchen description.",
        pricingTiers,
        images: content.kitchenImages?.map((img: any) => {
          const url = img.fields?.file?.url;
          if (url) {
            return url;
          } else {
            console.warn('Missing URL for image:', img);
            return '/images/placeholder-image.jpg';
          }
        }) || [
          '/images/kitchen1.jpg',
          '/images/kitchen2.jpg',
          '/images/kitchen3.jpg',
        ],
        introForHomepage: content.kitchenIntroForHomepage || "Default intro for homepage.",
      };
    } else {
      throw new Error('No kitchen data found');
    }
  } catch (error) {
    console.error('Error fetching kitchen data:', error);
    throw error;
  }
};

// Function to fetch data for the Bathroom page
export const fetchBathroomPage = async (): Promise<RoomData> => {
  try {
    const response = await client.getEntries({
      content_type: 'bathroomPage',
      limit: 1,
    });

    console.log('Bathroom Page Response:', response);

    if (response.items.length > 0) {
      const content = response.items[0].fields as any;

      console.log('Bathroom Images:', content.bathroomImages);

      const pricingTiers = await fetchPricingTiers(
        content.pricingTiers ? content.pricingTiers.map((tier: any) => tier.sys.id) : []
      );

      return {
        headline: content.bathroomHeadline || "Bathroom Headline",
        headlineImage: content.headlineBathroomImage?.fields?.file?.url || `/images/placeholder-bathroom.jpg`,
        mainImage: content.mainBathroomImage?.fields?.file?.url || `/images/placeholder-bathroom.jpg`,
        description: content.bathroomDescription || "Default bathroom description.",
        pricingTiers,
        images: content.bathroomImages?.map((img: any) => {
          const url = img.fields?.file?.url;
          if (url) {
            return url;
          } else {
            console.warn('Missing URL for image:', img);
            return '/images/placeholder-image.jpg';
          }
        }) || [
          '/images/bathroom1.jpg',
          '/images/bathroom2.jpg',
          '/images/bathroom3.jpg',
        ],
        introForHomepage: content.bathroomIntroForHomepage || "Default intro for homepage.",
      };
    } else {
      throw new Error('No bathroom data found');
    }
  } catch (error) {
    console.error('Error fetching bathroom data:', error);
    throw error;
  }
};
