// galleryData.tsx
import client from '../CustomComponents/Contentful/contentfulClient';

export interface ImageItem {
  type: 'image';
  imageUrl: string;
  category: string;
}

export const fetchGalleryImages = async (): Promise<ImageItem[]> => {
  try {
    const response = await client.getEntries({
      content_type: 'galleryImage', // Use the ID of the content type you created
    });

    return response.items.map((item: any) => {
      const fields = item.fields;
      return {
        type: 'image',
        imageUrl: fields.image.fields.file.url,
        category: fields.category,
      };
    });
  } catch (error) {
    console.error('Error fetching gallery images:', error);
    return [];
  }
};
