import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';

export default function Header() {
  const navigationLinks = [
    { name: 'HOME', path: '/' },
    { name: 'ABOUT', path: '/about' },
    { name: 'SERVICES', path: '/services' },
    { name: 'KITCHEN', path: '/kitchen' },
    { name: 'BATHROOM', path: '/bathroom' },
    { name: 'PROJECTS', path: '/projects' },
    { name: "FAQ'S", path: '/faq' },
    { name: 'CONTACT', path: '/contact', isPrimary: true }
  ];

  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleLinkClick = () => {
    setMobileMenuOpen(false);
  };

  return (
    <header className="sticky top-0 bg-white shadow-md z-50">
      <nav className="bg-white px-4 lg:px-8 py-4 border-b border-gray-200">
        <div className="flex items-center justify-between mx-auto max-w-screen-xl">
          {/* Logo */}
          <Link to="/" className="flex items-center" onClick={handleLinkClick}>
            <picture>
              <source srcSet="/images/logos/logo.png" type="image/webp" />
              <img src="/images/logos/logo.png" className="h-14" alt="Company Logo" />
            </picture>
          </Link>

          {/* Desktop Navigation */}
          <div className="hidden lg:flex lg:items-center lg:space-x-8">
            <ul className="flex items-center space-x-8">
              {navigationLinks.map((link, index) => (
                <li key={index}>
                  <NavLink
                    to={link.path}
                    className={({ isActive }) =>
                      `block font-light tracking-widest text-sm uppercase py-2 px-1 transition duration-300 ${
                        link.isPrimary
                          ? 'bg-gray-900 text-white px-4 py-2  hover:bg-gray-800'
                          : isActive
                          ? 'text-indigo-600 underline'
                          : 'text-gray-800 hover:text-indigo-600'
                      }`
                    }
                    onClick={handleLinkClick}
                  >
                    {link.name}
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>

          {/* Mobile Menu Toggle Button */}
          <div className="lg:hidden flex items-center">
            <button
              type="button"
              className="inline-flex items-center p-2 text-gray-800 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
              aria-controls="mobile-menu"
              aria-expanded={isMobileMenuOpen}
              onClick={toggleMobileMenu}
            >
              <span className="sr-only">Open main menu</span>
              {!isMobileMenuOpen ? (
                <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M3 5h14M3 10h14M3 15h14" />
                </svg>
              ) : (
                <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M6 6l8 8M6 14l8-8" />
                </svg>
              )}
            </button>
          </div>
        </div>

        {/* Mobile Menu */}
        {isMobileMenuOpen && (
          <div className="lg:hidden mt-4">
            <ul className="flex flex-col space-y-2">
              {navigationLinks.map((link, index) => (
                <li key={index}>
                  <NavLink
                    to={link.path}
                    className={({ isActive }) =>
                      `block text-sm font-light tracking-wider uppercase py-2 px-4 transition duration-300 ${
                        link.isPrimary
                          ? 'hidden' // Hide primary button (Contact) in mobile view
                          : isActive
                          ? 'text-indigo-600 underline'
                          : 'text-gray-800 hover:text-indigo-600'
                      }`
                    }
                    onClick={handleLinkClick}
                  >
                    {link.name}
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
        )}
      </nav>
    </header>
  );
}
