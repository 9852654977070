import client from '../CustomComponents/Contentful/contentfulClient';

interface ServiceData {
  id: string;
  title: string;
  description: string;
  content: string;
  images: string[];
}

export const fetchServicesData = async (): Promise<ServiceData[]> => {
  try {
    const response = await client.getEntries({
      content_type: 'eachService',
      order: ['fields.title'], // Correct: Passing 'order' as an array
    });

    return response.items.map((item: any) => {
      const fields = item.fields;
      const images = fields.images.map((image: any) => image.fields.file.url);

      return {
        id: item.sys.id,
        title: fields.title,
        description: fields.description,
        content: fields.content,
        images,
      };
    });
  } catch (error) {
    console.error('Error fetching services data:', error);
    throw error;
  }
};



// export const services = [
//   {
//     id: 1,
//     title: 'Design',
//     description: 'We provide comprehensive design services for your kitchen and bathroom projects.',
//     content: `Our design services include detailed planning and 3D modeling to bring your vision to life. We work closely with you to ensure that every detail meets your expectations and needs.

// Whether you're looking for a modern kitchen or a classic bathroom, our design team will create a space that reflects your style and preferences.

// Trust us to transform your ideas into reality with our professional design services.`,
//     images: ['/images/ServicesData/pexels-anthonyshkraba-production-8837511.jpg'], // Replace with relevant design image
//   },
//   {
//     id: 2,
//     title: 'Supply',
//     description: 'We supply high-quality materials and products for your kitchen and bathroom projects.',
//     content: `Our supply services include sourcing the best materials and products from top manufacturers. We offer a wide range of options to suit your style and budget.

// From cabinetry to fixtures, we ensure that all products meet the highest standards of quality and durability.

// Rely on us to provide everything you need for your kitchen and bathroom projects with our reliable supply services.`,
//     images: [ '/images/Home/JQELB7tw.jpeg'], // Replace with relevant supply image
//   },
//   {
//     id: 3,
//     title: 'Installation',
//     description: 'We offer professional installation services to ensure your kitchen and bathroom projects are completed to the highest standards.',
//     content: `Our installation team is skilled and experienced in fitting all aspects of kitchens and bathrooms. We take care of everything from plumbing to electrical work, ensuring a seamless installation process.

// We pride ourselves on our attention to detail and commitment to excellence, ensuring that your new space is functional, beautiful, and built to last.

// Count on us to handle your installation needs with precision and care.`,
//     images: ['/images/JayneRichard-1.png'], // Replace with relevant installation image
//   },
// ];
