import React, { createContext, useContext, ReactNode, useState, useEffect } from 'react';
import client from './Contentful/contentfulClient';
import { Entry } from 'contentful';

interface Project {
  id: string;
  title: string;
  description: any;  // RichText field from Contentful
  location: string;
  customerName: string;
  projectType: string;
  images: string[];
  keyFeatures: string[];
}

interface ProjectsContextProps {
  projects: Project[];
}

interface ProjectsProviderProps {
  children: ReactNode;
}

const ProjectsContext = createContext<ProjectsContextProps | undefined>(undefined);

export const ProjectsProvider: React.FC<ProjectsProviderProps> = ({ children }) => {
  const [projects, setProjects] = useState<Project[]>([]);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await client.getEntries({ content_type: 'projectDetails' });

        const loadedProjects: Project[] = response.items.map((item: Entry<any>) => {
          const fields = item.fields;

          const images = Array.isArray(fields.images)
            ? fields.images.map((img: any) => `https:${img.fields.file.url}`)
            : [];

          // Handle location field, which is an object with lat and lon properties
          const locationObj = fields.location;
          const location = locationObj && typeof locationObj === 'object' && 'lat' in locationObj && 'lon' in locationObj
            ? `${locationObj.lat}, ${locationObj.lon}`
            : '';

          const keyFeatures = typeof fields.keyFeatures === 'string'
            ? fields.keyFeatures.split(',')
            : [];

          return {
            id: item.sys.id,
            title: typeof fields.title === 'string' ? fields.title : '',
            description: fields.description || null,  // Handle the Rich Text Document
            location: location,
            customerName: typeof fields.customerName === 'string' ? fields.customerName : '',
            projectType: typeof fields.projectType === 'string' ? fields.projectType : '',
            images: images,
            keyFeatures: keyFeatures,
          };
        });

        setProjects(loadedProjects);
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    };

    fetchProjects();
  }, []);

  return (
    <ProjectsContext.Provider value={{ projects }}>
      {children}
    </ProjectsContext.Provider>
  );
};

export const useProjects = () => {
  const context = useContext(ProjectsContext);
  if (!context) {
    throw new Error('useProjects must be used within a ProjectsProvider');
  }
  return context;
};
