import React, { Suspense, lazy } from 'react';
import LoadingSpinner from './CustomComponents/Loading/LoadingSpinner';
import DelayedFallback from './CustomComponents/Loading/DelayedFallback';
import { Link } from 'react-router-dom';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import CookieConsent from 'react-cookie-consent';
import Layout from './Layout/layout';
import { ProjectsProvider } from './CustomComponents/projectsContext';
import { ThemeProvider } from './CustomComponents/darkmode';
import ScrollToTop from './CustomComponents/ScrollToTop';
import ProjectsDetails from './PageComponents/ProjectsComponent/ProjectsDetails';
import './App.css';
import Kitchen from './MainPages/Kitchen';
import Bathroom from './MainPages/Bathroom';
const Home = lazy(() => import('./MainPages/Home'));
const About = lazy(() => import('./MainPages/About'));
// const Kitchen = lazy(() => import('./MainPages/Kitchen'));
// const Bathroom = lazy(() => import('./MainPages/Bathroom'));
const Projects = lazy(() => import('./MainPages/Projects'))
const FAQ = lazy(() => import('./MainPages/faq'));
const Contact = lazy(() => import('./MainPages/contact'));
const ServiceDetail = lazy(() => import('./CustomComponents/serviceDetail'));
const ServicesHome = lazy(() => import('./MainPages/servicesHome'));
const TermsConditions = lazy(() => import('./MainPages/Legal/TermsConditions'));
const PrivacyPolicy = lazy(() => import('./MainPages/Legal/privacypolicy'));
const Unfound = lazy(() => import('./MainPages/404'));



function App() {
  return (
    <ThemeProvider>
      <ProjectsProvider>
        <BrowserRouter>
          <ScrollToTop />
          <Suspense fallback={<DelayedFallback><LoadingSpinner /></DelayedFallback>}>
            <Routes>
              <Route element={<Layout />}>
                <Route path='/' element={<Home />} />
                <Route path='/about' element={<About />} />
                <Route path='/faq' element={<FAQ />} />
                <Route path='/kitchen' element={<Kitchen />} />
                <Route path='/bathroom' element={<Bathroom />} />
                <Route path='/projects' element={<Projects />} />
                <Route path='/projects/:id' element={<ProjectsDetails />} />{/* Route for ProjectDetail */}
                <Route path='/services/:id' element={<ServiceDetail />} />
                <Route path='/services' element={<ServicesHome />} />
                <Route path='/contact' element={<Contact />} />
                <Route path='/privacypolicy' element={<PrivacyPolicy />} />
                <Route path='/termsandconditions' element={<TermsConditions />} />
                <Route path="*" element={<Unfound />} />
              </Route>
            </Routes>
          </Suspense>
          <CookieConsent
            location="bottom"
            buttonText="I understand"
            cookieName="myAwesomeCookieName"
            style={{ background: "#323D40" }}
            buttonStyle={{ color: "#fff", backgroundColor: "#C58C49", fontSize: "13px", transition: "background-color 0.3s" }}
            buttonClasses="cookie-consent-button"
            expires={150}
          >
            This website uses cookies to enhance the user experience.{" "}
            <Link 
              to="/privacy-policy" 
              aria-label="Read our Privacy Policy to understand how we use cookies and tracking" 
              style={{ color: "white" }} 
              className="hover:text-customBlue"
            >
              Learn more
            </Link>
          </CookieConsent>
        </BrowserRouter>
      </ProjectsProvider>
    </ThemeProvider>
  );
}

export default App;
