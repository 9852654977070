import React, { useEffect, useState } from 'react';
import FullscreenImage from '../../CustomComponents/FullScreenImage';
import { fetchGalleryImages, ImageItem } from '../../Data/galleryData';

interface ImageGalleryProps {
  category?: string; // The category prop is optional
}

const ImageGallery: React.FC<ImageGalleryProps> = ({ category }) => {
  const [items, setItems] = useState<ImageItem[]>([]);
  const [currentIndex, setCurrentIndex] = useState<number | null>(null);

  useEffect(() => {
    const loadGalleryImages = async () => {
      const images = await fetchGalleryImages();
      setItems(images);
    };

    loadGalleryImages();
  }, []);

  // Filter items by category
  const filteredItems = category
    ? items.filter((item) => item.category === category)
    : items;

  const handlePrevious = () => {
    if (currentIndex !== null) {
      setCurrentIndex((currentIndex - 1 + filteredItems.length) % filteredItems.length);
    }
  };

  const handleNext = () => {
    if (currentIndex !== null) {
      setCurrentIndex((currentIndex + 1) % filteredItems.length);
    }
  };

  const closeFullscreen = () => {
    setCurrentIndex(null);
  };

  return (
    <section className="bg-white dark:bg-dark-gray">
      <div className="px-0 xl:px-0 py-8 mx-auto  lg:py-16">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          {filteredItems.map((item, index) => (
            <div
              key={index}
              className="relative w-full overflow-hidden border border-black dark:border-white cursor-pointer"
              onClick={() => setCurrentIndex(index)}
              style={{ height: '500px' }}
            >
              <div className="w-full h-full group">
                <img
                  src={item.imageUrl}
                  alt={item.category}
                  className="w-full h-full object-cover transition-transform duration-300 ease-in-out group-hover:brightness-75"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      {currentIndex !== null && (
        <FullscreenImage
          imageUrl={filteredItems[currentIndex].imageUrl}
          onPrevious={handlePrevious}
          onNext={handleNext}
          onClose={closeFullscreen}
        />
      )}
    </section>
  );
};

export default ImageGallery;
