import React, { useEffect, useState } from 'react';
import KitchenBathroomHeader from '../CustomComponents/KitchenBathroomHeader';
import Head from '../PageComponents/KitchenBathroomComponent/Head';
import PricingTable from '../Data/pricingTable'; // Import the new PricingTable component
import { fetchBathroomPage, RoomData } from '../Data/kitchenBathroomData';
import ImageGallery from '../PageComponents/GalleryComponent/imagegallery';

export default function Bathroom() {
  const [bathroomData, setBathroomData] = useState<RoomData | null>(null);

  useEffect(() => {
    const loadBathroomData = async () => {
      try {
        const data = await fetchBathroomPage();
        setBathroomData(data);
      } catch (error) {
        console.error('Error loading bathroom data:', error);
      }
    };

    loadBathroomData();
  }, []);

  if (!bathroomData) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <KitchenBathroomHeader
        images={['/images/bathroomHead.jpg']}
        title={bathroomData.headline}
        subtitle="Luxurious Bathrooms"
        height="60vh"
      />
      <Head
        heading={bathroomData.headline}
        images={[bathroomData.mainImage]}
        paragraphs={[bathroomData.description]}
      />
     <PricingTable
  roomType="Bathroom" // or "Kitchen"
  title="Bathroom Pricing"
  subtitle="Choose the best plan for your bathroom"
  extraInfo="All prices include VAT and installation."
/>

      <ImageGallery category="bathroom" />
    </div>
  );
}
