import React, { useEffect, useState } from 'react';
import { CheckIcon } from '@heroicons/react/20/solid';
import client from '../CustomComponents/Contentful/contentfulClient';

export interface PricingTier {
  name: string;
  id: string;
  priceTotal: string;
  description: string;
  features: string[];
  mostPopular: boolean;
}

interface PricingTableProps {
  roomType: 'Kitchen' | 'Bathroom'; // Determines which room's pricing to fetch
  title: string;
  subtitle: string;
  extraInfo?: string;
}

const fetchPricingTiers = async (roomType: 'Kitchen' | 'Bathroom'): Promise<PricingTier[]> => {
  try {
    const response = await client.getEntries({
      content_type: 'pricingTable',
      'fields.roomType': roomType,
      limit: 1,
    });

    if (response.items.length > 0) {
      const content = response.items[0].fields as any;
      const pricingTiers = await Promise.all(
        content.pricingTiers.map(async (tier: any) => {
          const tierResponse = await client.getEntry(tier.sys.id);
          const tierFields = tierResponse.fields as any;

          return {
            name: tierFields.name || "Unknown Name",
            id: tier.sys.id,
            priceTotal: tierFields.priceTotal || "N/A",
            description: tierFields.description || "No description provided.",
            features: tierFields.features || [],
            mostPopular: tierFields.mostPopular || false,
          };
        })
      );
      return pricingTiers;
    } else {
      console.error(`No pricing data found for ${roomType}`);
      return [];
    }
  } catch (error) {
    console.error('Error fetching pricing tiers:', error);
    return [];
  }
};

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

const PricingTable: React.FC<PricingTableProps> = ({ roomType, title, subtitle, extraInfo }) => {
  const [pricingTiers, setPricingTiers] = useState<PricingTier[]>([]);

  useEffect(() => {
    const loadPricingData = async () => {
      const tiers = await fetchPricingTiers(roomType);
      setPricingTiers(tiers);
    };

    loadPricingData();
  }, [roomType]);

  if (pricingTiers.length === 0) {
    return <div>No pricing data available.</div>;
  }

  return (
    <div className="bg-gray-100 py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-4xl text-center">
          <h2 className="text-base font-light tracking-wide text-gray-500 font-poppins">{title}</h2>
          <p className="mt-2 text-3xl sm:text-4xl font-light text-gray-800 font-poppins">{subtitle}</p>
        </div>
        {extraInfo && (
          <p className="mx-auto mt-6 max-w-2xl text-center text-sm font-light text-gray-600 font-poppins">
            {extraInfo}
          </p>
        )}
        <div className="isolate mx-auto mt-16 grid max-w-md grid-cols-1 gap-y-8 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {pricingTiers.map((tier, tierIdx) => (
            <div
              key={tier.id}
              className={classNames(
                tier.mostPopular ? 'lg:z-10 lg:rounded-b-none' : 'lg:mt-8',
                tierIdx === 0 ? 'lg:rounded-r-none' : '',
                tierIdx === pricingTiers.length - 1 ? 'lg:rounded-l-none' : '',
                'flex flex-col justify-between rounded-3xl bg-gray-50 p-8 ring-1 ring-gray-200 xl:p-10',
              )}
            >
              <div>
                <div className="flex items-center justify-between gap-x-4">
                  <h3
                    id={tier.id}
                    className={classNames(
                      tier.mostPopular ? 'text-gray-700' : 'text-gray-800',
                      'text-lg font-light font-poppins',
                    )}
                  >
                    {tier.name}
                  </h3>
                  {tier.mostPopular ? (
                    <p className="rounded-full bg-gray-300 px-2.5 py-1 text-xs font-light leading-5 text-gray-700">
                      Most popular
                    </p>
                  ) : null}
                </div>
                <p className="mt-4 text-sm font-light leading-6 text-gray-600 font-poppins">{tier.description}</p>
                <p className="mt-6 flex items-baseline gap-x-1">
                  <span className="text-4xl font-light tracking-tight text-gray-900 font-poppins">{tier.priceTotal}</span>
                </p>
                <ul className="mt-8 space-y-3 text-sm leading-6 text-gray-600">
                  {tier.features.map((feature) => (
                    <li key={feature} className="flex gap-x-3">
                      <CheckIcon aria-hidden="true" className="h-6 w-5 flex-none text-gray-500" />
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
              {/* Removed Buy Plan button */}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PricingTable;
